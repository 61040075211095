import { themeGet } from "@styled-system/theme-get"
import { createGlobalStyle, css } from "styled-components"

// prettier-ignore
const reset = css`
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}
body {
  line-height: 1;
  overflow-x: hidden;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
  box-shadow: none;
}
a:hover {
  text-decoration: none;
}
`;

const ResetCSS = createGlobalStyle`
  ${reset}

  /* Theme */

  html {
    height:100%;
    box-sizing: border-box;
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }

  body {
    height:100%;
    display:block;
    color: ${themeGet("colors.text.default")};
    font-size: ${themeGet("fontSizes.body")};
    font-weight: ${themeGet("fontWeights[1]")};
    font-family: ${themeGet("fonts.default")};
    line-height: ${themeGet("lineHeights.body")};
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width:100%;
    -webkit-tap-highlight-color: transparent;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: ${themeGet("lineHeights.header")};
  }

  h1,
  h2,
  h3 {
    font-family: ${themeGet("fonts.headers")};
  }

  h1 {
    font-size: ${themeGet("fontSizes.7")};
    letter-spacing: ${themeGet("letterSpacings.0")};

    @media (max-width: ${themeGet("breakpoints.md")}) {
      font-size: ${themeGet("fontSizes.6")};
    }
  }
  h2 {
    font-size: ${themeGet("fontSizes.6")};
    letter-spacing: ${themeGet("letterSpacings.0")};
  }
  h3 {
    font-size: ${themeGet("fontSizes.5")};
    letter-spacing: ${themeGet("letterSpacings.0")};
  }
  h4 {
    font-size: ${themeGet("fontSizes.4")};
    letter-spacing: ${themeGet("letterSpacings.1")};
  }
  h5 {
    font-size: ${themeGet("fontSizes.3")};
    letter-spacing: ${themeGet("letterSpacings.1")};
  }
  h6 {
    font-size: ${themeGet("fontSizes.2")};
    letter-spacing: ${themeGet("letterSpacings.1")};
  }

  h1, h2 {
    font-weight: ${themeGet("fontWeights.3")};
  }
  h3, h4, h5, h6 {
    font-weight: ${themeGet("fontWeights.3")};
  }

  input, textarea, select, button {
    font-size: ${themeGet("fontSizes.input")};
  }

  strong {
    font-weight: ${themeGet("fontWeights.3")};
  }

  img {
    max-width: 100%;
    vertical-align: top;
    height: auto;
  }

  p + p {
    margin-top: ${themeGet("sizes.4")};
  }

  p > a {
    text-decoration: underline;
    font-weight: 500;
  }

  a {
    cursor: pointer;
    outline: none;
    color: ${themeGet("colors.primary.default")};

    transition: color 0.3s;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;

    &:hover {
      color: ${themeGet("colors.primary.hover")};
    }
    &:active {
      color: ${themeGet("colors.primary.pressed")};
    }
  }

  .tooltip {
    max-width: ${themeGet("sizes.64")} !important;
    border-radius: ${themeGet("radii.2")} !important;
    padding: ${themeGet("sizes.2")} !important;
  }
`

export default ResetCSS
