/**
 * Breakpoints for responsive design
 */
export const breakpoints = ["748px", "1064px", "1228px", "1428px"]
export const breakpointsRem = ["47rem", "58.5rem", "70rem", "87.5rem"]

/**
 * This is needed for object responsive breakpoints to work.
 * At the moment, we require the keys to be `sm`, `md`, `lg` and `xl`
 */
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

breakpointsRem.sm = breakpointsRem[0]
breakpointsRem.md = breakpointsRem[1]
breakpointsRem.lg = breakpointsRem[2]
breakpointsRem.xl = breakpointsRem[3]
