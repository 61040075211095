import React from "react"
import styled from "styled-components"
import { desktopNav } from "../../data/site"
import { Box } from "../core"
import { SmartLink } from "../smartlink"
import { LargeNav } from "./large-nav"
import { Nav } from "./nav"

export function Header({ large }) {
  const DesktopLinks = (
    <>
      {desktopNav.menu.map(({ label, path, external }) => (
        <SmartLink key={label} label={label} path={path} external={external} />
      ))}
    </>
  )
  return <HeaderWrapper>{large ? <LargeNav /> : <Nav />}</HeaderWrapper>
}

const HeaderWrapper = styled(Box).attrs(() => ({
  as: "header",
  marginBottom: ["80px"],
  fontSize: 2,
}))``
