// import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { themeGet } from "@styled-system/theme-get"
import { AnimatePresence, motion } from "framer-motion"
import addToMailchimp from "gatsby-plugin-mailchimp"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useWindowSize } from "../../lib/hooks/useWindowSize"
import { Box, Button } from "../core"

export function Subscribe() {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const { width, height } = useWindowSize()

  useEffect(() => {
    if (error) {
      setError(false)
    }
  }, [setError, email])

  const trackSignup = () => {
    window.dataLayer.push({
      event: "subscribeSubmit",
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    trackSignup()
    addToMailchimp(email, { FNAME: name })
      .then((data) => {
        setSuccess(true)
        setEmail("")
        setName("")
      })
      .catch(() => {
        setError(true)
      })
  }

  return (
    <>
      <AnimatePresence initial={false}>
        {success && (
          <motion.div
            key="success"
            style={{ position: "relative", zIndex: 1000, pointerEvents: "none" }}
            initial={{ opacity: 0, scale: 1.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 1, transition: { opacity: { duration: 0.1 } } }}
          >
            <SuccessWrapper>SUBSCRIBED!</SuccessWrapper>
          </motion.div>
        )}
      </AnimatePresence>
      <Box as="form" onSubmit={handleSubmit} my={6}>
        <StyledInput placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
        <StyledInput
          placeholder="Email"
          value={email}
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Button as="button" variant="primary" size="large" fontSize={2} minWidth={"100%"}>
          Subscribe
        </Button>
      </Box>
    </>
  )
}

const StyledInput = styled.input`
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 12px;
  background-color: white;
  border: solid 2px;
  border-color: ${themeGet("colors.black")};
  padding: 15px 13px 15px;
  width: 100%;
  height: inherit;
  color: ${themeGet("colors.text.default")};
  box-shadow: none; /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  caret-color: ${themeGet("colors.blue._700")};
  resize: none;

  &:focus {
    border-color: ${themeGet("colors.blue._700")};
    outline: none;
  }
`

const SuccessWrapper = styled(Box).attrs(() => ({
  py: ["24px"],
  px: ["48px"],
  fontSize: [6],
  fontWeight: [3],
}))`
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 20px);
  border: 6px solid white;
  box-shadow: 0px 0px 0px 3px ${themeGet("colors.black")};
  background: ${themeGet("colors.accent.default")};
  color: ${themeGet("colors.black")};
  transform: rotate(-17.5deg);
`
