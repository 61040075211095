import styled from "styled-components"
import {
  border,
  BorderProps,
  buttonStyle,
  ButtonStyleProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  size,
  SizeProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from "styled-system"

const buttonSize = variant({
  prop: "size",
  key: "buttonSizes",
})

type ButtonProps = ButtonStyleProps & BorderProps & ColorProps & SizeProps & SpaceProps & TypographyProps & LayoutProps

const Button = styled("a")<ButtonProps>(
  {
    cursor: "pointer",
    border: 0,
    outline: 0,
    margin: 0,
    display: "inline-flex",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "center",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  border,
  buttonSize,
  buttonStyle,
  color,
  layout,
  size,
  space,
  typography,
)

Button.defaultProps = {
  minWidth: "space.16",
  lineHeight: 2,
  variant: "primary",
  size: "large",
}

export default Button
