export const links = {
  home: {
    label: "Home",
    path: "/",
    external: false,
  },
  guides: {
    label: "Guides",
    path: "/",
    external: false,
  },
  products: {
    label: "Products",
    path: "/products",
    external: false,
  },
  about: {
    label: "About",
    path: "/about",
    external: false,
  },
  contribute: {
    label: "Become a Contributor",
    path: "/contribute",
    external: false,
  },
  twitter: {
    label: "Twitter",
    path: "https://twitter.com/Lemonlabco",
    external: true,
  },
  privacy: {
    label: "Privacy",
    path: "/privacy",
  },
  terms: {
    label: "Terms",
    path: "/terms",
  },
  contactUs: {
    label: "Contact Us",
    path: "mailto:hello@lemonlab.co",
    external: true,
  },
}

export const desktopNav = {
  primaryCta: links.contribute,
  menu: [links.home, links.products, links.about],
}

export const mobileNav = {
  primaryCta: links.contribute,
  menu: [links.home, links.products, links.about, links.contribute],
}

export const footerNav = {
  copyright: `© ${new Date().getFullYear()} Lemon Lab`,
  learnMore: [links.guides, links.products, links.about, links.contribute, links.contactUs],
  terms: links.terms,
}
