import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import { Box, Image } from "../core"
import { Container } from "../core/container"
import { Lead } from "../core/typography"
import { Subscribe } from "./subscribe"

export function Pivot({ wide }) {
  const illoOptions = ["a", "b", "c"]
  const filename = `graphics/newsletter-${illoOptions[Math.floor(Math.random() * illoOptions.length)]}.png`

  return (
    <PivotWrapper>
      <InnerWrapper wide={wide}>
        <GraphicWrapper>
          <Image filename={filename} />
        </GraphicWrapper>
        <Box>
          <h3>Never miss a review.</h3>
          <Lead>Get our weekly brief of science-backed snippets.</Lead>
          <Box maxWidth={["100%", "100%", "400px"]}>
            <Subscribe />
          </Box>
        </Box>
      </InnerWrapper>
    </PivotWrapper>
  )
}

const PivotWrapper = styled(Box).attrs(() => ({
  mt: [80, 160],
}))`
  width: 100%;
  border-top: 2px solid ${themeGet("colors.black")};
`

const InnerWrapper = styled(Container).attrs(() => ({
  py: ["64px"],
}))`
  @media (min-width: ${themeGet("breakpoints.md")}) {
    display: grid;
    grid-gap: 64px;
    grid-template-columns: 1fr minmax(500px, 50%);
    direction: rtl;

    & > * {
      direction: ltr;
    }
  }
`

const GraphicWrapper = styled(Box).attrs(() => ({
  mb: ["40px", "40px", "0"],
  mx: ["auto", "auto", "0"],
  maxWidth: [300, 300, 400],
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 100%;
  }
`
