import { graphql, useStaticQuery } from "gatsby"
import Img, { FluidObject, GatsbyImageOptionalProps } from "gatsby-image"
import React from "react"

type ImageProps = { filename: string } & GatsbyImageOptionalProps

function Image(props: ImageProps) {
  const { filename, ...rest } = props
  const data = useStaticQuery<GatsbyTypes.ImageComponentQuery>(graphql`
    query ImageComponent {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  const image = data.images.edges.find((n) => {
    return n.node.relativePath.includes(filename)
  })
  if (!image) {
    return null
  }

  const fluid = image.node.childImageSharp?.fluid as FluidObject
  return <Img loading="lazy" fadeIn={true} fluid={fluid} {...rest} />
}

export default Image
