import { themeGet } from "@styled-system/theme-get"
import styled from "styled-components"
import Box from "./box"

export const SanSerifTextWrapper = styled(Box).attrs(() => ({}))`
  ul {
    padding-left: 1em;
  }

  ul li {
    list-style: disc;
  }

  ol {
    padding-left: 1em;
    list-style: none;
    counter-reset: my-awesome-counter;
  }

  ol li {
    position: relative;
    counter-increment: my-awesome-counter;
    padding-left: 0.5em;
  }

  ol li::marker {
    content: counter(my-awesome-counter) ".";
    font-weight: bold;
    padding-left: 0.5em;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 1.5em 0 0.5em 0;
  }

  p {
    margin: 0.5em 0;
  }

  li {
    margin: 1em 0;
  }

  a {
    text-decoration: underline;
  }
`

export const SerifTextWrapper = styled(SanSerifTextWrapper).attrs(() => ({}))`
  ol,
  ul,
  p {
    font-family: ${themeGet("fonts.body")};
    font-size: ${themeGet("fontSizes.body")};
    line-height: ${themeGet("lineHeights.serif")};
  }
`

export const Text = styled(Box).attrs(() => ({
  as: "p",
}))`
  font-family: ${themeGet("fonts.body")};
`

export const TextSecondary = styled(Text).attrs(() => ({
  color: "text.secondary",
}))``

export const Lead = styled(Box).attrs(() => ({
  as: "h3",
  mb: ["32px"],
  fontSize: [4],
  lineHeight: 2,
  fontWeight: 1,
}))``
