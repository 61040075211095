import { Link } from "gatsby";
import React from "react";

type SmartLinkProps = {
  label: string;
  path: string;
  external: boolean;
};

export const SmartLink = ({ label, path, external }: SmartLinkProps) => {
  if (external) {
    return <a href={path}>{label}</a>;
  } else {
    return <Link to={path}>{label}</Link>;
  }
};

SmartLink.defaultProps = { external: false };
