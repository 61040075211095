import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Helmet from "react-helmet"

export function SEO({ description, lang, keywords, title, pathname }) {
  const { site } = useStaticQuery(
    graphql`
      query SEO {
        site {
          siteMetadata {
            siteUrl
            title
            description
            keywords
            twitterUsername
            contactEmail
          }
        }
      }
    `,
  )

  const siteMetadata = site.siteMetadata

  const seo = {
    title: title || siteMetadata.title,
    desc: description || siteMetadata.description,
    keywords: keywords || siteMetadata.keywords,
    imageUrl: `https://lemonlab.co/social/lemonlab.png`,
  }

  const canonical = pathname ? `${siteMetadata?.siteUrl}${pathname}` : null

  const jsonld = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: siteMetadata.siteUrl,
    name: "Lemon Lab",
    contactPoint: {
      "@type": "ContactPoint",
      email: siteMetadata.contactEmail,
      contactType: "Customer service",
    },
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate="Lemon Lab | %s"
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : [{ rel: "preconnect", href: "//www.google-analytics.com" }]
      }
      meta={[
        {
          name: `description`,
          content: seo.desc,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.desc,
        },
        {
          property: `og:type`,
          content: `website`,
        },

        {
          property: "og:image",
          content: seo.imageUrl,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.twitterUsername,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.desc,
        },
        {
          name: `keywords`,
          content: seo.keywords?.join(`, `),
        },
      ]}
    >
      <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}
