import React from "react";
import styled from "styled-components";
import { color } from "styled-system";

export type SvgIconProps = Omit<React.SVGProps<SVGSVGElement>, "ref">;

export const SvgIcon = styled.svg<SvgIconProps>`
  display: inline-block;
  user-select: none;
  flex-shrink: 0;

  ${color}
`;
