export const heights = {
  row: {
    small: "44px",
    medium: "64px",
  },
  control: {
    small: "40px",
    medium: "48px",
    large: "56px",
  },
};
