import { themeGet } from "@styled-system/theme-get"
import React, { useState } from "react"
import { GiHamburgerMenu } from "react-icons/gi"
import styled from "styled-components"
import { Menu, Overlay, StyledOffCanvas } from "styled-off-canvas"
import { mobileNav } from "../../data/site"
import { Box } from "../core"
import { SmartLink } from "../smartlink"

export function MobileNav() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <MobileWrapper>
      <StyledOffCanvas overlayOpacity={0.5} width="250px" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <GiHamburgerMenu
          size={22}
          onClick={() => {
            setIsOpen((isOpen) => !isOpen)
          }}
        />

        <Menu>
          <ul>
            {mobileNav.menu.map(({ label, path, external }) => (
              <li key={label}>
                <SmartLink key={label} label={label} path={path} external={external} />
              </li>
            ))}
          </ul>
        </Menu>

        {isOpen ? <Overlay /> : null}
      </StyledOffCanvas>
    </MobileWrapper>
  )
}

const MobileWrapper = styled(Box).attrs(() => ({
  display: ["block", "none"],
}))`
  ul {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  li {
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  a {
    color: ${themeGet("colors.text.default")};
    transition: "color 100ms ease";
    flex-shrink: 0;
    padding: 12px 20px;
    font-weight: ${themeGet("fontWeights.2")};

    &.active {
      color: ${themeGet("colors.gray._900")};
    }

    &:hover {
      color: ${themeGet("colors.gray._900")};
    }
  }
`
