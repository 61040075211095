const shadows = [
  "0px 0px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(0, 0, 0, 0), 0px 3px 6px rgba(0, 0, 0, 0)",
  "0px 0px 1px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 3px 6px rgba(0, 0, 0, 0.04)",
  "0px 0px 1px rgba(0, 0, 0, 0.12), 0px 2px 6px rgba(0, 0, 0, 0.03), 0px 10px 20px rgba(0, 0, 0, 0.03)",
  "0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.06)",
  "0px 0px 1px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.04)",
];

shadows.none = shadows[0];
shadows.card = shadows[1];
shadows.tile = shadows[0];
shadows.tileHover = shadows[1];
shadows.dropdown = shadows[1];

export default shadows;
