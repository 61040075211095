export function getColor(colorName) {
  let background = "#BFC6DE"
  let foreground = "black"

  switch (colorName) {
    case "CD1":
      background = "#0045E8"
      foreground = "white"
      break
    case "CD2":
      background = "#06196E"
      foreground = "white"
      break
    case "CD3":
      background = "#008234"
      foreground = "white"
      break
    case "CD4":
      background = "#0F4A40"
      foreground = "white"
      break
    case "CD5":
      background = "#8900CA"
      foreground = "white"
      break
    case "CD6":
      background = "#404060"
      foreground = "white"
      break

    case "WD1":
      background = "#D94985"
      foreground = "white"
      break
    case "WD2":
      background = "#A03535"
      foreground = "white"
      break
    case "WD3":
      background = "#F07C4B"
      foreground = "white"
      break
    case "WD4":
      background = "#A96A0B"
      foreground = "white"
      break
    case "WD5":
      background = "#95275C"
      foreground = "white"
      break

    case "CL1":
      background = "#B8BFFF"
      break
    case "CL2":
      background = "#BFC6DE"
      break
    case "CL3":
      background = "#D6C8FE"
      break
    case "CL4":
      background = "#D6D6E2"
      break
    case "CL5":
      background = "#B2D1A8"
      break
    case "CL6":
      background = "#C6DEDD"
      break

    case "WL1":
      background = "#FFA9A9"
      break
    case "WL2":
      background = "#E7BAB1"
      break
    case "WL3":
      background = "#F9B669"
      break
    case "WL4":
      background = "#DAB3CA"
      break
    case "WL5":
      background = "#F9CF24"
      break
    case "WL6":
      background = "#D0C8BD"
      break
  }

  return { background, foreground }
}

export function getContributorColor(name) {
  const getHash = (input) => {
    var hash = 0,
      len = input.length
    for (var i = 0; i < len; i++) {
      hash = (hash << 5) - hash + input.charCodeAt(i)
      hash |= 0 // to 32bit integer
    }
    return hash
  }

  const Hash = getHash(name).toString()

  const WarmVsCool = Hash[0] <= 5 ? "W" : "C"
  const LightVsDark = Hash[1] <= 5 ? "L" : "D"
  let number = parseInt(Hash[2]) + 1
  if (number > 5) number = number - 4

  const colorName = WarmVsCool + LightVsDark + number

  return getColor(colorName)
}
