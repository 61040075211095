import React from "react"
import { Helmet } from "react-helmet"
import styled, { ThemeProvider } from "styled-components"
import theme from "../theme"
import { Container } from "./core/container"
import { Footer } from "./footer"
import { Header } from "./header/index.jsx"
import { Pivot } from "./pivot"
import ResetCss from "./reset-css"

export function Layout({ wide, pivot = true, children }) {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <ResetCss />
      <Header large={wide} />
      <Main wide={wide}>{children}</Main>
      {pivot && <Pivot wide={wide} />}
      <Footer wide={wide} />
    </ThemeProvider>
  )
}

const Main = styled(Container).attrs(() => ({
  as: "main",
  marginBottom: ["80px"],
  minHeight: ["60vh"],
}))``
