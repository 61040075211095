export type FontFaceType = {
  fontFamily: string
  fontStyle: string
  fontDisplay: string
  fontWeight: number
  src: string
}

export const fonts = ["Inter var,Inter,sans-serif", "Lora,Inter,sans-serif"]

fonts.default = fonts[0]
fonts.body = fonts[1]

export const fontSizes = [
  "0.75rem", //  0 12px
  "0.875rem", //  1 14px
  "1rem", //     2 16px
  "1.1875rem", //   3 19px
  "1.5rem", //  4 24px
  "1.75rem", // 5 28 px
  "3rem", // 6 48px
  "4rem", //  7 64px
]

fontSizes.body = fontSizes[3]

export const fontWeights = [300, 400, 500, 600, 1000]
export const lineHeights = [1.2, 1.4, 1.6, 1.8, 2.0, 2.15]

lineHeights.header = lineHeights[0]
lineHeights.lead = lineHeights[2]
lineHeights.body = lineHeights[2]
lineHeights.serif = lineHeights[3]
lineHeights.small = lineHeights[1]

export const letterSpacings = ["-0.02em", "0.02em"]

const typography = {
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
}

export default typography
