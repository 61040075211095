const gray = {
  _0: "#eaedf7",
  _50: "#e8eaf5",
  _100: "#e6e8f3",
  _200: "#dee0eb",
  _300: "#d2d4de",
  _400: "#c0c2cc",
  _500: "#a8aab2",
  _600: "#888a91",
  _700: "#616268",
  _800: "#333438",
  _900: "#050505", // https://www.colorbox.io/#steps=10#hue_start=230#hue_end=230#hue_curve=linear#sat_start=4#sat_end=6#sat_curve=easeInQuad#sat_rate=130#lum_start=97#lum_end=2#lum_curve=easeOutQuad#minor_steps_map=0,none,none
}

const grayRGBA = {
  _0: "rgba(0, 32, 139, 0.05)",
  _50: "rgba(0, 21, 137, 0.07)",
  _100: "rgba(0, 20, 128, 0.1)",
  _200: "rgba(0, 15, 98, 0.14)",
  _300: "rgba(0, 12, 72, 0.21)",
  _400: "rgba(0, 7, 48, 0.26)",
  _500: "rgba(0, 7, 37, 0.37)",
  _600: "rgba(0, 9, 30, 0.47)",
  _700: "rgba(0, 2, 16, 0.68)",
  _800: "rgba(0, 3, 13, 0.82)",
  _900: "rgba(0, 0, 7, 0.93)",
}

const yellow = {
  _600: "#FFD02B",
  _700: "#FFC600",
  _800: "#F2BC00",
}

const blue = {
  _0: "#f3f4fc",
  _50: "#dee1fc",
  _100: "#c9cffc",
  _200: "#a1aafc",
  _300: "#7e8afc",
  _400: "#6070fc",
  _500: "#485afa",
  _600: "#364af8",
  _700: "#283df3",
  _800: "#1e32e9",
  _900: "#162ad8",
  _1000: "#1123bf",
}

const red = "#EB5757"
const white = "#FFFFFF"

const colors = {
  gray,
  grayRGBA,
  blue,
  yellow,
  red,
  white,
  black: gray._900,

  primary: {
    default: gray._900,
    hover: gray._900,
    pressed: gray._900,
  },

  accent: {
    default: yellow._700,
    hover: yellow._600,
    pressed: yellow._800,
  },

  background: {
    white: white,
    light: gray._50,
    medium: gray._200,
    card: gray._50,
    modal: white,
    banner: gray._100,
    bannerDanger: "#F6F0F0",
    progress: gray._200,
    avatar: "linear-gradient(90deg, rgb(172, 179, 188) 0%, rgb(165, 171, 180) 100%)",
  },
  text: {
    default: grayRGBA._900,
    secondary: grayRGBA._700,
    disabled: grayRGBA._600,
  },
}

export default colors

/*
primary: {
    default: blue._700,
    hover: blue._800,
    pressed: blue._900,
  },

  text: {
    default: grayRGBA._900,
    secondary: grayRGBA._700,
    disabled: grayRGBA._600,
  },

  background: {
    white: white,
    light: gray._50,
    medium: gray._200,
    card: gray._50,
    modal: white,
    banner: gray._100,
    bannerDanger: "#F6F0F0",
    progress: gray._200,
    avatar: "linear-gradient(90deg, rgb(172, 179, 188) 0%, rgb(165, 171, 180) 100%)",
  },

  border: {
    input: gray._200,
    inputActive: blue._700,
    inputHovered: gray._500,
    fine: gray._100,
    fineHover: gray._200,
    fineRGBA: "rgba(0, 0, 0, 0.05)",
    thickRGBA: "rgba(0, 0, 0, 0.01)",
  },

  button: {
    primary: blue._700,
    primaryHover: blue._800,
    primaryPressed: blue._900,

    primaryText: white,
    primaryDisabled: "#F0F2F5",
    primaryTextDisabled: gray._500,

    secondary: grayRGBA._100,
    secondaryHover: grayRGBA._200,
    secondaryPressed: grayRGBA._300,

    // copied from primary
    secondaryDisabled: "#F0F2F5",
    secondaryTextDisabled: gray._500,

    tertiary: white,
    tertiaryHover: white,
    tertiaryPressed: white,
    link: blue._700,
    linkHover: blue._800,
    linkPressed: blue._900,
    white,

    row: white,
    rowHover: gray._100,
    rowHoverAdd: blue._0,
    rowHoverCard: gray._200,
  },

  icon: {
    info: {
      default: gray._500,
      hover: gray._700,
    },
    customers: {
      default: "rgba(0, 0, 0, 0.5)",
    },
    logos: {
      default: gray._700,
      hover: blue._700,
    },
  },

  form: {
    background: white,
    error: red,
    errorBackground: "#ffefef",
    progressDot: gray._200,
    progressDotActive: gray._200,
  },

  tooltip: {
    text: white,
    background: gray._900,
  },

*/
