import border from "./border"
import { breakpoints, breakpointsRem } from "./breakpoints"
import colors from "./colors"
import { heights } from "./heights"
import shadows from "./shadows"
import { baseSizes, sizes } from "./sizes"
import typography, { fontSizes, lineHeights } from "./typography"

const zIndices = {
  hide: -1,
  auto: "auto",
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
}

const theme = {
  colors,
  breakpoints,
  heights,
  space: baseSizes,
  sizes,
  zIndices,
  shadows,
  ...border,
  ...typography,

  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1.5, // rem
    outerMargin: 1.25, // rem
    mediaQuery: "only screen",
    container: {
      sm: 47, // rem
      md: 58.5, // rem
      lg: 70, // rem (1080px)
    },
    breakpoints: {
      sm: parseInt(breakpointsRem.sm.substring(-3)),
      md: parseInt(breakpointsRem.md.substring(-3)),
      lg: parseInt(breakpointsRem.lg.substring(-3)),
    },
  },

  textStyles: {
    lg: {
      fontSize: fontSizes[3],
      lineHeight: lineHeights[3],
    },
    md: {
      fontSize: fontSizes[2],
      lineHeight: lineHeights[2],
    },
    sm: {
      fontSize: fontSizes[1],
      lineHeight: lineHeights[1],
    },
    xs: {
      fontSize: fontSizes[0],
      lineHeight: lineHeights[0],
    },
  },

  buttons: {
    primary: {
      backgroundColor: colors.blue._700,
      "&:hover": {
        backgroundColor: colors.blue._800,
      },
      "&:active": {
        backgroundColor: colors.blue._900,
      },
      color: colors.white,
    },
    secondary: {
      backgroundColor: colors.grayRGBA._100,
      "&:hover": {
        backgroundColor: colors.grayRGBA._200,
      },
      "&:active": {
        backgroundColor: colors.grayRGBA._300,
      },
      color: colors.gray._900,
    },
  },
  buttonSizes: {
    small: {
      fontSize: fontSizes[2],
      padding: `4px 16px`,
      height: `40px`,
    },
    medium: {
      fontSize: fontSizes[2],
      padding: `6px 16px`,
      height: "48px",
      minWidth: "64px",
    },
    large: {
      fontSize: fontSizes[3],
      padding: `8px 22px`,
      height: "56px",
    },
  },
}

export default theme
