import { themeGet } from "@styled-system/theme-get"
import styled from "styled-components"
import { Box } from "./"

export const Container = styled(Box).attrs(() => ({
  px: ["24px", "24px", "32px", "64px"],
  mx: "auto",
}))`
  max-width: ${(props) => (props.wide ? themeGet("breakpoints.xl") : themeGet("breakpoints.lg"))};
`
