import styled from "styled-components";
import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  SizeProps,
  TextStyleProps,
  TypographyProps,
  border,
  color,
  flexbox,
  layout,
  position,
  shadow,
  size,
  space,
  textStyle,
  typography,
} from "styled-system";

export type BoxProps = BorderProps &
  ColorProps &
  FlexboxProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  SizeProps &
  SpaceProps &
  TextStyleProps &
  TypographyProps;

const Box = styled.div<BoxProps>`
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${position}
  ${shadow}
  ${size}
  ${space}
  ${textStyle}
  ${typography}
`;

export default Box;
