import { themeGet } from "@styled-system/theme-get"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { desktopNav } from "../../data/site"
import { Box } from "../core"
import { LemonLabWordMark } from "../icons/logo"
import { SmartLink } from "../smartlink"
import { MobileNav } from "./mobile-nav"

export function Nav() {
  return (
    <Wrapper>
      <InnerWrapper>
        <LeftWrapper>
          <Link to="/">
            <LogoWrapper>
              <LemonLabWordMark />
            </LogoWrapper>
          </Link>
        </LeftWrapper>
        <LinksWrapper>
          <Box display={["none", "block"]}>
            {desktopNav.menu.map(({ label, path, external }) => (
              <SmartLink key={label} label={label} path={path} external={external} />
            ))}
            <SmartLink label={desktopNav.primaryCta.label} path={desktopNav.primaryCta.path} />
          </Box>
          <MobileNav />
        </LinksWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(Box).attrs(() => ({}))`
  border-bottom: 2px solid ${themeGet("colors.black")};
`

const InnerWrapper = styled(Box).attrs(() => ({
  height: ["88px"],
  px: ["24px", "24px", "32px", "64px"],
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${themeGet("breakpoints.xl")};
  margin-left: auto;
  margin-right: auto;
`

const LeftWrapper = styled(Box).attrs(() => ({}))`
  display: flex;
  align-items: center;
`

const LogoWrapper = styled(Box).attrs(() => ({}))`
  display: flex;
  align-items: center;

  svg {
    color: ${themeGet("colors.black")};
    height: 35px;
    width: auto;
  }
`

const LemonLabDot = styled(Box).attrs(() => ({
  width: ["20px", "20px"],
  height: ["20px", "20px"],
  marginRight: ["4px"],
}))`
  background: ${themeGet("colors.accent.default")};
  border: 2px solid ${themeGet("colors.black")};
  border-radius: 200px;
  transition: background 200ms linear;
`

const LemonLabTagline = styled(Box).attrs(() => ({
  display: ["none", "none", "block"],
  marginLeft: ["12px"],
  fontSize: [1, 2],
  fontWeight: 1,
}))`
  text-align: center;
  color: ${themeGet("colors.text.secondary")};
  white-space: nowrap;
`

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2px;

  a {
    color: ${themeGet("colors.black")};
    transition: "color 100ms ease";
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 24px;
    }

    &.active {
      color: ${themeGet("colors.gray._900")};
    }

    &:hover {
      color: ${themeGet("colors.gray._700")};
    }
  }
`
