const radii = ["4px", "6px", "8px", "12px", "20px", "50px", "100px"];
// const borders = [];
// const borderWidths = [];
// const borderStyles = [];

export default {
  //   borders,
  //   borderWidths,
  //   borderStyles,
  radii,
};
